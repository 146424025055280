








































import Vue from 'vue';
import {Component, Prop, VModel} from 'vue-property-decorator';
import UploadFile from '@/interfaces/UploadFile';
import {namespace} from 'vuex-class';
import {locationStoreActions} from '@/stores/location.store';

const LocationStore = namespace('location');

@Component({})

export default class UploadDataComponent extends Vue {

  @LocationStore.Action(locationStoreActions.DELETE_FILE_ACTION)
  private deleteFileAction!: (payload: { locationId: string, fileId: string }) => Promise<void>;


  @VModel({default: [], required: true})
  private files_arr!: UploadFile[];

  @Prop({default: undefined})
  public locationId!: string;

  private showDeleteDialog: boolean = false;
  private selectedFile?: UploadFile;

  private uploadFile() {
    (this.$refs.file as HTMLElement).click();
  }

  private onFileChange(event: any) {
    [...(event.target.files)].forEach((file) => this.files_arr.push({
      id: '',
      data: file,
    }));
  }

  public onRemoveClick(file_to_remove: UploadFile, index: number) {
    if (!file_to_remove.id) {
      this.files_arr = this.files_arr.filter((file) => file !== file_to_remove);
    } else {
      this.selectedFile = file_to_remove;
      this.showDeleteDialog = true;
    }
  }

  private async deleteFile() {
    this.showDeleteDialog = false;
    try {
      await this.deleteFileAction({locationId: this.locationId, fileId: this.selectedFile!.id});
      this.files_arr = this.files_arr.filter((file) => file !== this.selectedFile);
      delete this.selectedFile;
      this.$notifySuccessSimplified('CUSTOMER_DASHBOARD.NOTIFICATIONS.FILE_DELETE.SUCCESS');
    } catch {
      this.$notifyErrorSimplified('CUSTOMER_DASHBOARD.NOTIFICATIONS.FILE_DELETE.ERROR');
    }
  }
}
